import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.section`
    margin: 64px auto 160px;
    max-width: 1200px;
    padding: 0 24px;

    > ul {
        display: flex;
        flex-direction: column;
        list-style: none;

        ${media.mdUp} {
            flex-direction: row;
        }
    }

    ${media.mdUp} {
        margin: 128px auto 200px;
    }

    ${media.xlUp} {
        margin: 158px auto 200px;
    }
`;
