import {
    Container,
    Description,
    Title
} from "components/homePage/usps/usp/Usp.styled";
import { Content } from "components/shared/content/Content";

interface UspProps {
    description: string;
    icon: React.ReactElement;
    title: string;
}

export function Usp({ description, icon, title }: UspProps): JSX.Element {
    return (
        <Container>
            <header>
                {icon}
                <Title>{title}</Title>
            </header>
            <Description>
                <Content content={description} />
            </Description>
        </Container>
    );
}
