import DeliveryTruckIconAnimation from "public/assets/lottie/delivery-truck-icon.json";
import FreezbagsAnimation from "public/assets/lottie/freezebags-icon.json";
import KnivesAnimation from "public/assets/lottie/knifes-icon.json";
import dynamic from "next/dynamic";

import { Container } from "components/homePage/usps/Usps.styled";
import { Usp } from "components/homePage/usps/usp/Usp";

import { GenericStoryBlok } from "types/storyblok";

const LottieWrapper = dynamic(
    () => import("components/shared/lottieWrapper/LottieWrapper")
);

type Props = {
    blocks: Array<GenericStoryBlok>;
};

export function Usps({ blocks }: Props): JSX.Element {
    function getIcon(icon: string) {
        switch (icon) {
            case "knives":
                return KnivesAnimation;
            case "freeze-bags":
                return FreezbagsAnimation;
            case "delivery-truck":
                return DeliveryTruckIconAnimation;
            default:
                return null;
        }
    }

    return (
        <Container aria-label="Mr. Fillet USPs">
            <ul>
                {blocks.map((item, index) => (
                    <Usp
                        key={`Usp-item-${item.title}-${index}`}
                        title={item.title}
                        description={item.description}
                        icon={
                            <LottieWrapper
                                width="64px"
                                height="64px"
                                triggerWheneverInView
                                playOnMouseEnter
                                isRepeating
                                config={{
                                    animationData: getIcon(item.icon),
                                    autoplay: true,
                                    loop: false
                                }}
                            />
                        }
                    />
                ))}
            </ul>
        </Container>
    );
}
